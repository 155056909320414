@tailwind base;
@tailwind components;
@tailwind utilities;

select {
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    background-image: url("/public/arrow.svg");
    background-position: calc(100% - 20px);
    background-repeat: no-repeat;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 0.375rem;
    color: var(--black);
    display: block;
    height: 3rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: left;
    width: 100%;
}
